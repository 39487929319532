import { DEFAULT_AFFILIATE_ID } from 'src/pages/_middleware/defaultAffiliate';

export const AFFILIATE_EMAIL = 'email';

export const AFFILIATE_CRITEO = 'criteo';
export const AFFILIATE_FINDHOTEL = 'findhotel';
export const AFFILIATE_VIO = 'vio';
export const AFFILIATE_GHA = 'gha';
export const AFFILIATE_HOTELSCAN = 'hotelscan';
export const AFFILIATE_KAYAK = 'kayak';
export const AFFILIATE_SKYSCANNER = 'skyscanner';
export const AFFILIATE_TRIPADVISOR = 'tripadvisor';
export const AFFILIATE_TRIVAGO = 'trivago';
export const AFFILIATE_IDEALO = 'idealo';
export const AFIILIATE_EBOOKING = 'ebooking';
export const AFFILIATE_VIPDISTRICT = 'vipdistrict';
export const AFFILIATE_COZYCOZY = 'cozycozy';

export const AFFILIATE_NAMES = {
  [AFFILIATE_EMAIL]: 'Email',
  [AFFILIATE_FINDHOTEL]: 'Vio',
  [AFFILIATE_VIO]: 'Vio',
  [AFFILIATE_GHA]: 'GHA',
  [AFFILIATE_HOTELSCAN]: 'HotelScan',
  [AFFILIATE_KAYAK]: 'Kayak',
  [AFFILIATE_TRIPADVISOR]: 'TripAdvisor',
  [AFFILIATE_TRIVAGO]: 'Trivago',
  [AFFILIATE_SKYSCANNER]: 'Skyscanner',
  [AFFILIATE_IDEALO]: 'Idealo',
  [AFFILIATE_COZYCOZY]: 'Cozycozy'
};

export const AFFILIATE_LIST = Object.keys(AFFILIATE_NAMES);

export const ALL_AFFILIATES = [
  AFFILIATE_EMAIL,
  AFFILIATE_CRITEO,
  AFFILIATE_FINDHOTEL,
  AFFILIATE_VIO,
  AFFILIATE_GHA,
  AFFILIATE_TRIVAGO,
  AFFILIATE_HOTELSCAN,
  AFFILIATE_KAYAK,
  AFFILIATE_SKYSCANNER,
  AFFILIATE_TRIPADVISOR,
  AFFILIATE_IDEALO,
  AFFILIATE_VIPDISTRICT,
  DEFAULT_AFFILIATE_ID,
];
export const NO_CUG_AFFILIATES = [AFFILIATE_VIPDISTRICT];
